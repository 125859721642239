import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import Home from './Home.vue';
import { routes as routes3d } from './scenes';
import { routes as utils } from './utils';

const routes: Array<RouteRecordRaw> = [
  ...routes3d,
  ...utils,
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      title: 'd3va::sandbox',
      noTitleTemplate: true,
    },
  },
  {
    path: '/NOTICE',
    component: () => import('./ThirdPartyNotices.vue'),
    meta: {
      title: 'Third party notice',
    },
  },
  {
    // Not Found
    path: '/:pathMatch(.*)*',
    component: () => import('@/layouts/Page.vue'),
    props: {
      error: 404,
    },
    meta: {
      title: '404 Not Found',
    },
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior: async () => ({ top: 0 }),
});

export default router;
