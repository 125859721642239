import { RouteRecordRaw, RouterView } from 'vue-router';

export const routes: readonly RouteRecordRaw[] = [
  {
    path: '/scenes',
    component: RouterView,
    children: [
      {
        path: 'webgl2-initialize',
        component: () => import('./Init.vue'),
        meta: {
          demo: true,
          title: 'WebGL2 初期化',
          tags: ['lib'],
        },
      },
      {
        path: 'checklist',
        component: () => import('./Check.vue'),
        meta: {
          demo: true,
          title: '機能チェックリスト',
          tags: ['lib'],
        },
      },
      {
        path: 'input',
        component: () => import('./Input.vue'),
        meta: {
          demo: true,
          title: '入力チェック',
          tags: ['input'],
        },
      },
      {
        path: 'ifs',
        component: () => import('./ifs/Scene.vue'),
        meta: {
          demo: true,
          title: 'IFS',
          thumbnail: require('./ifs/thumbnail.png'),
          tags: ['shape'],
        },
      },
      {
        path: 'icosahedron-sphere',
        component: () => import('./icosahedron-sphere/Scene.vue'),
        meta: {
          demo: true,
          title: 'Icosahedron sphere',
          thumbnail: require('./icosahedron-sphere/thumbnail.png'),
          tags: ['shape'],
        },
      },
      {
        path: 'physics',
        component: () => import('./physics/Scene.vue'),
        meta: {
          demo: true,
          title: 'kawaii physics',
          thumbnail: require('./physics/thumbnail.png'),
          tags: ['physics'],
        },
      },
      {
        path: 'cloth',
        component: () => import('./cloth/Scene.vue'),
        meta: {
          demo: true,
          title: 'kawaii cloth',
          thumbnail: require('./cloth/thumbnail.png'),
          tags: ['wip', 'physics'],
        },
      },
    ],
  },
];
