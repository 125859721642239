import { createApp } from 'vue';
import App from './App.vue';
import router from './views';
import MaterialIcons from './components/MaterialIcons.vue';

const app = createApp(App);

app.component('MaterialIcons', MaterialIcons);

app.use(router);
app.mount(document.body);
