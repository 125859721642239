import { RouteRecordRaw, RouterView } from 'vue-router';

export const routes: readonly RouteRecordRaw[] = [
  {
    path: '/utils',
    component: RouterView,
    children: [{
      path: 'texture',
      component: RouterView,
      children: [{
        path: 'repeat',
        component: () => import('./RepeatTexture.vue'),
        meta: {
          demo: true,
          tags: ['util'],
          title: '反復テクスチャ生成',
        },
      }],
    }],
  },
];
