export const references: {
  [url: string]: {
    title: string;
    description?: string;
  };
} = {
  'https://www.khronos.org/registry/webgl/conformance-suites/1.0.2/conformance/misc/instanceof-test.html': {
    title: 'WebGL instanceof test.',
    description: '各WebGLオブジェクトは instanceof できる',
  },
  'https://gameworksdocs.nvidia.com/PhysX/4.1/documentation/physxguide/Manual/Index.html': {
    title: 'PhysX 4.1 User\'s Guide',
  },
  'https://gameworksdocs.nvidia.com/PhysX/4.1/documentation/physxapi/files/index.html': {
    title: 'PhysX 4.1 API Documentation',
  },
};
